html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	overflow: auto;
	min-width: 390px;
}

html > body {
	font-family: 'Figtree', -apple-system, BlinkMacSystemFont, Roboto,
		'Helvetica Neue', sans-serif;
}

body::-webkit-scrollbar {
	display: none;
}

#root {
	width: 100%;
	max-height: 100%;
	height: 100%;
	overflow: hidden;
}

#menu-appbar ul {
	padding: 0;
}

div[portal-container] {
	position: relative;
	z-index: 1500;
}

input,
textarea {
	font-family: inherit;
	border: none;
}

@keyframes slideInUp {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes slideOutUp {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.slideInUp {
	animation: slideInUp 300ms;
}

.slideOutUp {
	animation: slideOutUp 400ms;
}
